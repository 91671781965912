<template>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1"><h5>Tickets No Pagados ({{ stats.count }})</h5></div>

          <div class="bloc2"><h3>Total:
            {{ stats.mmonto_aceptado ? formatCurrency(Number(stats.mmonto_aceptado)) : formatCurrency(0) }}</h3></div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-2">
      <div class="card p-fluid" id="number-container">
        <!--     Filter goes here     -->
        <Filter
            @get-data="getData"
            page="paid"
        />
      </div>
    </div>

    <div class="col-12 lg:col-10">
      <div class="card">
        <div id="overlay" v-if="loading2"></div>

        <DataTable :value="tickets"
                   :paginator="true"
                   class="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                   showGridlines
                   :rows="10"
                   v-model:filters="filters1"
                   filterDisplay="menu"
                   :loading="loading1"
                   responsiveLayout="scroll"
                   @filter="onFilter($event)"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]" #
                   currentPageReportTemplate="{first} al {last} de {totalRecords}">

          <ColumnGroup type="header">
            <Row>
              <Column header="Ticket"
                      :sortable="true"
                      field="tticket"
                      bodyStyle="text-align: left; width: 120px !important;">
                <template #body="{ data}">
                  <Button
                      class="t-tickets"
                      type="button"
                      :style="getColour(data.mmonto_aceptado)"
                      :label="data.tticket"
                      @click="openWinnerDetailDialog(data)"
                  />
                </template>
              </Column>

              <Column header="Fecha"
                      :sortable="true"
                      field="dtransaccion"
                      bodyStyle="text-align: right;">
                <template #body="{data}">
                  {{ formatDate(data.dtransaccion) }}
                </template>
              </Column>

              <Column header="Sorteo"
                      :sortable="true"
                      field="tproductoxsorteo"
                      bodyStyle="text-align: left"
                      footerStyle="text-align: right">
                <template #footer>
                  Total:
                </template>
              </Column>

              <Column header="Monto" :sortable="true" field="mmonto_aceptado" bodyStyle="text-align: right;"
                      footerStyle="text-align: right">>
                <template #body="{data}">
                  {{ formatCurrency(data.mmonto_aceptado) }}
                </template>
                <template #footer>
                  {{ stats.mmonto_aceptado ? formatCurrency(stats.mmonto_aceptado) : formatCurrency(0) }}
                </template>
              </Column>

              <Column header="Bloque" :sortable="true" field="tcomercializador" bodyStyle="text-align: left;"/>
              <Column header="Banca" :sortable="true" field="tbanca" bodyStyle="text-align: left;"/>
              <Column header="Distribuidor" :sortable="true" field="tdistribuidor" bodyStyle="text-align: left;"/>
              <Column header="Punto de Venta" :sortable="true" field="tagencia" bodyStyle="text-align: left;"/>
              <Column header="Estatus" :sortable="true" field="tstatus" bodyStyle="text-align: left;">
                <template #body="{data}">
                  <div :class="statusClass(data)">
                    {{ data.tstatus }}
                  </div>
                </template>
              </Column>

            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
    </div>
  </div>

  <WinnerDetailDialog
      :header="header"
      :data="winnerTicketDetail"
      :game="selectedGame"
      :lista="selectedLista"
      :show="openWinnerDialog"
      @close="closeWinnerDetailDialog"/>

</template>

<script>
import TicketService from "@/service/backend/TicketService";
import WinnerDetailDialog from "@/components/Tickets/Dialogs/WinnerDetailDialog";
import generalMixin from '@/mixins/generalMixin.js'
import Filter from "@/components/Filters/Filter";


export default {
  mixins: [generalMixin],
  components: {
    'WinnerDetailDialog': WinnerDetailDialog,
    'Filter': Filter,
  },
  data() {
    return {
      loading1: false,
      loading2: false,
      tickets: [],
      componentKey: 0,
      selectedDate: new Date(),
      max: 0,
      winnerTicketDetail: [],
      openWinnerDialog: false,
      header: null,
      total: 0,
      filters: [],
      selectedDistribuidor: null,
      vuex: [],
      selectedGame: false,
      selectedLista: false,
      games: [
        {name: 'Lotería', code: 'lottery'},
        {name: 'Animalitos', code: 'animal',}
      ],
      stats: {count: 0, sum: 0},
      page: 'winners',
      override: null,
    }
  },
  saleService: null,
  created() {
    this.ticketService = new TicketService();
    this.$primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubrer", "Noviembre", "Diciembre"];
    this.$primevue.config.locale.dayNamesMin = ["Do", "Lu", "Ma", "Mi", "Ju", "Vr", "Sa"];
  },
  mounted() {
  },
  methods: {
    async getData(filters) {
      this.filters = filters;
      this.selectedGame = filters.game;
      this.selectedLista = filters.lista;
      filters.cstatus_transaccion = 2; // 4 = PAGADO
      filters.unpaid = true;
      this.loading1 = true;
      const response = await this.ticketService.getTableData(filters);
      this.tickets = response.rows;
      this.stats = response.stats;
      this.max = response.stats.max_aceptado;

      this.loading1 = false
      this.componentKey += 1;

      this.override = response;
    },
    async openWinnerDetailDialog(params) {
      this.loading2 = true;
      params.game = this.selectedGame;
      params.lista = this.selectedLista;
      params.id_transaccion = params.num_transaccion;
      const response = await this.ticketService.getWinnerTicketDetail(params);
      this.winnerTicketDetail = response.data;
      this.header = params.tticket
      this.openWinnerDialog = true;
    },
    closeWinnerDetailDialog() {
      this.openWinnerDialog = false;
      this.loading2 = false;
    },

  },
}
</script>
<style>
.block_container {
  display: flex;
  justify-content: space-between;
}

.t-tickets {
  margin-bottom: 5px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.producto {
  font-size: x-small;
  margin: 2px 0 2px 0;
  /*background-color: #004a49;*/
  /*border-color: #004a49;*/
  width: 140px;
  /*color: white;*/
}

.t-tickets {
  width: 100%;
  font-weight: bold;
}
</style>